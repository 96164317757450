import Button from 'react-bootstrap/Button';

 function VoteButtons({answers, onClick, username, socket}) {
        

  return (
    <div className="d-grid gap-2">
        
        {answers.map(({socketId,currentAnswer}) => (
        socket.id !== socketId && currentAnswer !==null
        ? (<Button onClick={onClick} value ={socketId} variant="primary" size="lg">{currentAnswer.answer}</Button> )
        : null
        ))}
    </div>)

}

export default VoteButtons;