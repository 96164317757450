import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Man1 from '../../Man1.png';
import Man2 from '../../Man2.png';
import Man3 from '../../Man3.png';
import Man4 from '../../Man4.png';
import Woman1 from '../../Woman1.png';
import Woman2 from '../../Woman2.png';
import Woman3 from '../../Woman3.png';
import Woman4 from '../../Woman4.png';
import Body from '../../components/Body';
import CharacterImage from '../../components/CharacterImage';
import TvAttention from '../../components/TvAttention';

const Char_Select = ({roomcode,socket}) => {
const [ManTaken1, setManTaken1] = useState(false);
const [ManTaken2, setManTaken2] = useState(false);
const [ManTaken3, setManTaken3] = useState(false);
const [ManTaken4, setManTaken4] = useState(false);
const [WomanTaken1, setWomanTaken1] = useState(false);
const [WomanTaken2, setWomanTaken2] = useState(false);
const [WomanTaken3, setWomanTaken3] = useState(false);
const [WomanTaken4, setWomanTaken4] = useState(false);
const [characterSelected, setCharacterSelected] = useState(false);
const navigate = useNavigate();

useEffect(() => {
  //someone clicked start game
  socket.on('intro_complete_server', () => {
  navigate('/movie_round', { replace: true });

  });
  return () => socket.off('intro_complete_server');
  }, [socket]);



useEffect(() => {
  //db updated
  socket.on('char_taken', (data) => {
 //   navigate('/waiting_room', { replace: true });
  const {socketid, char} = data;
  if(socketid === socket.id)
  {
    setCharacterSelected(true);
    console.log("my selection worked. char is " +  char);
  }
  else
  {
    switch (char) {
      case "'Man1'":
        setManTaken1(true);
        console.log(char);
        break;
      case "'Man2'":
        setManTaken2(true);
        break;
      case "'Man3'":
        setManTaken3(true);
        break;
      case "'Man4'":
          setManTaken4(true);
          break;
      case "'Woman1'":
        setWomanTaken1(true); 
        break;   
      case "'Woman2'":
        setWomanTaken2(true);  
        break; 
      case "'Woman3'":
        setWomanTaken3(true);   
        break;
      case "'Woman4'":
        setWomanTaken4(true);
        break;   
      default:
        console.log('char not found');
    }
  }
  }); 
  return () => socket.off('char_taken');
  }, [socket]);



//Click On Char

const handleImageClick = (imageName) => {
 // setClickedImage(imageName); // Set the clicked image name
  console.log(`Clicked on: ${imageName}`);
  socket.emit('character_selected', {roomcode:roomcode.current, char:imageName});

}
  return (

  <Body>
  
  {(!characterSelected) && <h1 className="text-center">Select your character</h1>}
  {(!characterSelected) &&
    <Container fluid className="d-flex flex-column" style={{ height: "calc(100vh - 60px - 5rem)" }}>
    {/* First Row */}
    <Row className="w-100" style={{ height: '20%' }}>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Man1} imageValue = 'Man1' charName = "Darryl" handleImageClick={handleImageClick} isClicked = {ManTaken1} />
      </Col>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Woman1} imageValue = "Woman1" charName = "Judy" handleImageClick={handleImageClick} isClicked = {WomanTaken1}/>
      </Col>
    </Row>

    {/* Second Row */}
    <Row className="w-100" style={{ height: '20%' }}>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
    
        <CharacterImage imageName= {Man2} imageValue = "Man2" charName = "Jeff" handleImageClick={handleImageClick} isClicked = {ManTaken2}/>
    
      </Col>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Woman2} imageValue = "Woman2" charName = "Fawn" handleImageClick={handleImageClick} isClicked = {WomanTaken2}/>
      </Col>
    </Row>

    {/* Third Row */}
    <Row className="w-100" style={{ height: '20%' }}>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Man3} imageValue = "Man3" charName = "Goggles" handleImageClick={handleImageClick} isClicked = {ManTaken3}/>
      </Col>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Woman3} imageValue = "Woman3" charName = "Bertha" handleImageClick={handleImageClick} isClicked = {WomanTaken3}/>
      </Col>
    </Row>
    {/* Fourth Row */}
    <Row className="w-100" style={{ height: '20%' }}>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Man4} imageValue = "Man4" charName = "Soot" handleImageClick={handleImageClick} isClicked = {ManTaken4}/>
      </Col>
      <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <CharacterImage imageName= {Woman4} imageValue = "Woman4" charName = "Jester" handleImageClick={handleImageClick} isClicked = {WomanTaken4}/>
      </Col>
    </Row>
  </Container>
}
{characterSelected && <TvAttention/>}
</Body>

  );
};

export default Char_Select;