import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Body from '../../components/Body';
import InputField from '../../components/InputField';
import { useNavigate } from 'react-router-dom';
import useScrollBlock from '../../components/useScrollBlock';
import TvAttention from '../../components/TvAttention';

const Answer_Round = ({username,roomcode,movie,answers,socket}) => {
    const [formErrors, setFormErrors] = useState({});
    const [hasAnswered, setHasAnswered] = useState(false);
    const answerField = useRef();
    const navigate = useNavigate();
    const [blockScroll, allowScroll] = useScrollBlock();
    blockScroll();

    useEffect(() => {
        answerField.current.focus();
      }, []);


    useEffect(() => {
    //voting round started
    socket.on('vote_round_started', (parodies) => {
        // console.log(parodies);
        // let aiParodies = JSON.parse(movie.current[0].parody).parody_titles;
        //     //let randAiParody = (aiParodies[Math.floor(Math.random() * aiParodies.length)]);
        //     //shuffle the array
        // for (var i = aiParodies.length - 1; i > 0; i--) {
        //     var j = Math.floor(Math.random() * (i + 1));
        //     var temp = aiParodies[i];
        //     aiParodies[i] = aiParodies[j];
        //     aiParodies[j] = temp;
        // }
        // parodies['ai1'] = aiParodies[0];
        // parodies['ai2'] = aiParodies[1];
        answers.current = parodies;
        navigate('/vote_round', { replace: true });
    });
    return () => socket.off('vote_round_started');
    }, [socket]);


     

    const onSubmit = (ev) => {
            ev.preventDefault();
            const answer = answerField.current.value;
        const errors = {};
        if (!answer) {
            errors.answer = 'Answer must not be empty.';
        }
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        else
        {
            socket.emit('player_answered', {roundType: "parody", answer:{answer:answer,fightChoice:null,fightPlayer:null}, roomcode:roomcode.current});
            console.log(`You entered ${answer}`);
            setHasAnswered(true);
        }

}

//limit character count of form entry
const handleChange = (e) => {
    if (answerField.current.value.length >= 60) {
        answerField.current.value = answerField.current.value.slice(0,60);
    }
  }




// <CountdownTimer seconds  = {60} />
    return (

    <Body >
        
       
        {!hasAnswered && 
            <div>
                <h1 >Write a {movie.current.roundType} for the movie: <div style = {{ color: '#0d6efd',display: 'inline'}}>{movie.current.title}</div></h1>    
            
                <Form onSubmit={onSubmit} onChange={handleChange}>
                    <InputField name="answer" label="Answer" error={formErrors.answer} fieldRef={answerField}/>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form> 
            </div>}
            {hasAnswered && 
                <TvAttention/>
            }
            
</Body>

    );
};

export default Answer_Round;