import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Body from '../../components/Body';
import InputField from '../../components/InputField';
import { useNavigate } from 'react-router-dom';
import useScrollBlock from '../../components/useScrollBlock';
import Cookies from 'js-cookie'

const Home = ({username,roomcode,socket,movie, answers}) => {
const [formErrors, setFormErrors] = useState({});
const [userName, setuserName] = useState("");
const usernameField = useRef();
const roomcodeField = useRef();
const navigate = useNavigate();
const [reconnect, setReconnect] = useState(false);
const [blockScroll, allowScroll] = useScrollBlock();


blockScroll();

useEffect(() => {
  usernameField.current.focus();
}, []);

useEffect(() => {
  //db updated
  socket.on('player_added_to_db', () => {
  navigate('/waiting_room', { replace: true });
  }); 
  return () => socket.off('player_added_to_db');
  }, [socket]);


  useEffect(() => {
    //db updated
    socket.on('connect', () => {
      console.log("connected");
      const testCookie = Cookies.get('io');
      console.log('io cookie ' + testCookie);
    }); 
    return () => socket.off('connect');
    }, [socket]);

    useEffect(() => {
     
      //add reconnect button to screen
      socket.on('reconnectOption', () => {
        setReconnect(true);
      }); 
      return () => socket.off('reconnectOption');
      }, [socket]);

      //navigate to games current round page
    useEffect(() => {
      socket.on('reconnect_round', (data) => {
        roomcode.current = data.room;
        switch (data.round){
          case 'wait':
            navigate('/waiting_room', { replace: true });
            break;
          case 'answer':
            movie.current = data.data;
            console.log('movie is ' + movie.current);
            navigate('/answer_round', { replace: true });
            break;
          case 'vote':
            //answers.current = data.data; need a way to prevent self-voting
            navigate('/vote_round', { replace: true });
            break;
          case 'score':
            navigate('/score_round', { replace: true });
            break;
        }

      }); 
      return () => socket.off('reconnect_round');
      }, [socket]);




//Submit button click
const onSubmit = (ev) => {
  ev.preventDefault();
  username.current = usernameField.current.value;
  roomcode.current = roomcodeField.current.value.toUpperCase();
  console.log(`You entered ${username.current}:${roomcode.current}`);
 
  const errors = {};
  if (!username) {
    errors.username = 'Username must not be empty.';
  }
  if (!roomcode) {
    errors.roomcode = 'Roomcode must not be empty.';
  }
  setFormErrors(errors);
  if (Object.keys(errors).length > 0) {
    return;
  }
  else 
  {
    socket.emit('join_room', {username:username.current, roomcode:roomcode.current});
    //write another emission for when the database is updated before navigating
    console.log(`Join Room Emitted`);
    
  }
};


//limit character count of form entry
const handleChange = (e) => {

  if (usernameField.current.value.length >= 20) {
    usernameField.current.value = usernameField.current.value.slice(0,20);
  }
  if (roomcodeField.current.value.length >= 4) {
    roomcodeField.current.value = roomcodeField.current.value.slice(0,4);
  }
}

//reconnect button press
const onClick = (ev) => {
  ev.preventDefault();
  socket.emit('reconnect_requested',);
}

  return (

  <Body>
        <Form onSubmit={onSubmit} onChange={handleChange}>
          <InputField name="username" label="Username" error={formErrors.username} fieldRef={usernameField}   />
          <InputField name="roomcode" label="Roomcode" error={formErrors.roomcode} fieldRef={roomcodeField}  />
          <Button variant="primary" type="submit">Submit</Button>
          <br/><br/>
          <p class = "tos"><small>By clicking Submit, you agree to our <a href ="https://www.gethotreels.com/tos/" target="_blank" rel="noreferrer">Terms of Use</a></small></p>
          <p class = "ppolicy"><small> <a href ="https://www.gethotreels.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a></small></p>
        </Form>
        <br/><br/>

        {reconnect &&
         <Button onClick={onClick} variant="primary" type="submit">Reconnect</Button>
        }


  </Body>

  );
};

export default Home;