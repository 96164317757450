import Button from 'react-bootstrap/Button';

 function FightButtons({onFightClick}) {
        

  return (
    <div className="d-grid gap-2">
        Attacks
        <Button onClick={onFightClick} value ="punch" variant="primary" size="lg">Face Punch</Button>
        <Button onClick={onFightClick} value ="kick" variant="primary" size="lg">Crotch Kick</Button>
        <Button onClick={onFightClick} value ="kiss" variant="primary" size="lg">Kiss</Button>
        Blocks
        <Button onClick={onFightClick} value ="blockFace" variant="primary" size="lg">Block Face</Button>
        <Button onClick={onFightClick} value ="blockCrotch" variant="primary" size="lg">Block Crotch</Button>      

      
    </div>)

}

export default FightButtons;