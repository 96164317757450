

 

 
 function CharacterImage ({ imageName, imageValue, handleImageClick, isClicked, charName }) {

  const cardStyle = {
    position: 'relative',
   // backgroundColor: '#343a40', // Dark background for the card
    color: 'white',
    cursor: isClicked ? 'not-allowed' : 'pointer', //make unclickable, // Pointer cursor for hover
    opacity: isClicked ? 0.5 : 1, // Adjust opacity based on isClicked
    height: '100%',
    widght:'100%',
    overflow: 'hidden',
    aspectRatio: '1 / 1'
  };
  
  const imageStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'cover', // Ensure the image adjusts properly
  };
  
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
 
  const titleStyle = {
    position: 'absolute',
    bottom: '1rem', // Adjust distance from the bottom
    left: '50%',
    transform: 'translateX(-50%)', // Center horizontally
    textAlign: 'center',
    fontSize: '1.5rem',
    color: 'white',
    fontSize: 'clamp(1rem, 2.5vw, 2rem)',
  //  WebkitTextStroke: '1px black',
    textShadow: `
    -1px -1px 0 black, 
    1px -1px 0 black, 
    -1px 1px 0 black, 
    1px 1px 0 black`

  }

  return (
    
<div
      style={cardStyle}
      onClick={() => {
        if (!isClicked) {
          handleImageClick(`'${imageValue}'`);
        }
      }}
    >
      <img
        src={imageName}
        alt="Card Image"
        style={imageStyle}
      />
      <div style={overlayStyle}>
        <h5 style={titleStyle} className="card-title">{charName}</h5>
      </div>
    </div>
  );
}

export default CharacterImage;

