import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom'; 
import { useState, useEffect, useRef } from 'react';
import useScrollBlock from '../../components/useScrollBlock';
import TvAttention from '../../components/TvAttention';
import FightButtons from '../../components/FightButtons';
import PlayerSelectButtons from '../../components/PlayerSelectButtons';

const Fight_Round = ({roomcode, answers, movie, socket}) => {
    const [showFight, setShowFight] = useState(true);
    const [showPlayer, setShowPlayer] = useState(false);
    const fightChoice = useRef('');
    const playerChoice = useRef('');
    const navigate = useNavigate();
    const [blockScroll, allowScroll] = useScrollBlock();
    blockScroll();
  
    useEffect(() => {
        //voting round ended
        socket.on('answer_round_started', (roundMovie) => {
            movie.current.title = roundMovie.title;
            movie.current.roundType = roundMovie.roundType;
            navigate('/answer_round', { replace: true });
        });
        return () => socket.off('answer_round_started');
        }, [socket]);









    const onFightClick = (ev) => {
        ev.preventDefault();
        fightChoice.current = ev.target.value;
        setShowFight(false);
        if(fightChoice.current !== "blockFace" && fightChoice.current !== "blockCrotch")
        {
            setShowPlayer(true);
        }
        else
        {
            socket.emit('player_answered', {roundType: "fight", answer:{answer:"fight",fightChoice:fightChoice.current,fightPlayer:null}, roomcode:roomcode.current});
            
        }
    }

    const onPlayerClick = (ev) => {
        ev.preventDefault();
        playerChoice.current = ev.target.value;
        socket.emit('player_answered', {roundType: "fight", answer:{answer:"fight",fightChoice:fightChoice.current,fightPlayer:playerChoice.current}, roomcode:roomcode.current});
        setShowPlayer(false);
    }

    return (
//NEED TO CHANGE IT SO IT ADDS USER TO A STACK OF USERS <CountdownTimer seconds  = {20} />
    <Body >
            


            
            {showFight && 
            <div>
                <h1> What action would you like to take?</h1>
                <br></br>
                <FightButtons onFightClick = {onFightClick} />
            </div>
            }
            {showPlayer &&
            <div>
                         <h1> Which player would you like to {fightChoice.current}?</h1>
                         <br></br>
                         <PlayerSelectButtons onPlayerClick = {onPlayerClick} answers = {answers.current} socket = {socket}/>
                     </div>
            }
            {!showFight && !showPlayer && <TvAttention/>}
    </Body>

    );
};

export default Fight_Round;
