import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Body from '../../components/Body';
import InputField from '../../components/InputField';
import { useNavigate } from 'react-router-dom';
import useScrollBlock from '../../components/useScrollBlock';
import TvAttention from '../../components/TvAttention';

const Movie_Round = ({username,movie,roomcode,socket}) => {
    const [formErrors, setFormErrors] = useState({});
    const [hasAnswered, setHasAnswered] = useState(false);
    const movie1Field = useRef();
    const movie2Field = useRef();
    const setting1Field = useRef();
    const setting2Field = useRef();
    const navigate = useNavigate();
    const [blockScroll, allowScroll] = useScrollBlock();
    blockScroll();

    // useEffect(() => {
    //     movie1Field.current.focus();
    //   }, []);


    useEffect(() => {
        //intro done
        socket.on('answer_round_started', (roundMovie) => {
            movie.current.title = roundMovie.title;
            movie.current.roundType = roundMovie.roundType;
            console.log(movie.current.title);
            navigate('/answer_round', { replace: true });
        });
        return () => socket.off('answer_round_started');
        }, [socket]);


     

    const onSubmit = (ev) => {
            ev.preventDefault();
            const movie1 = movie1Field.current.value;
            const movie2 = movie2Field.current.value;
            const setting1 = setting1Field.current.value.toLowerCase();
            const setting2 = setting2Field.current.value.toLowerCase();

        const errors = {};
        if (!movie1 || !movie2 || !setting1 || !setting2) {
            errors.answer = 'Answer must not be empty.';
        }
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        else
        {
            const answer = [movie1,movie2];
            const settings = [setting1,setting2];
          //  answer.push(movie1,movie2);
           // settings.push(setting1,setting2);
            socket.emit('player_answered', {roundType: "movie", answer:{answer:answer,fightChoice:null,fightPlayer:null, settings:settings}, roomcode:roomcode.current});
            setHasAnswered(true);
        }

}

//limit character count of form entry
const handleChange = (e) => {
    const fields = [movie1Field, movie2Field ,setting1Field, setting2Field]; // Add all field references here
    
    fields.forEach((field) => {
      if (field.current.value.length >= 30) {
        field.current.value = field.current.value.slice(0, 60);
      }
    });
  };



    return (

    <Body >
        
       
        {!hasAnswered && 
            <div>
                <h1 >Provide 2 movie titles and settings:</h1>    
            
                <Form onSubmit={onSubmit} onChange={handleChange}>
                    <InputField name="movie1" label="Movie Title (Ex. Citizen Cane)" error={formErrors.answer} fieldRef={movie1Field}/>
                    <InputField name="movie2" label="Movie Title 2" error={formErrors.answer} fieldRef={movie2Field}/>
                    <InputField name="setting1" label="Setting (Ex. A zoo)" error={formErrors.answer} fieldRef={setting1Field}/>
                    <InputField name="setting2" label="Setting 2" error={formErrors.answer} fieldRef={setting2Field}/>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form> 
            </div>}
            {hasAnswered && 
                <TvAttention/>
            }
            
</Body>

    );
};

export default Movie_Round;