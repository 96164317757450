import Button from 'react-bootstrap/Button';

 function PlayerSelectButtons({answers, onPlayerClick, socket}) {
        

  return (
    <div className="d-grid gap-2">
        
        {answers.map(({socketId,name}) => (
        socket.id !== socketId
        ? (<Button onClick={onPlayerClick} value ={socketId} variant="primary" size="lg">{name}</Button> )
        : null
        ))}
    </div>)

}

export default PlayerSelectButtons;